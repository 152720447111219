<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="신규년도 무재해 부서추가" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="생성"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-plant type="edit" :required="true" :editable="editable" v-model="data.plantCd" />
            </div>
            <div class="col-3">
              <c-datepicker
                :editable="editable"
                :required="true"
                type="year"
                label="대상년도"
                name="uninjuryYear"
                v-model="data.uninjuryYear"
              />
            </div>
            <div class="col-6">
              <c-datepicker
                :editable="editable"
                :required="true"
                label="인원기준일"
                name="standardDate"
                v-model="data.standardDate"
              />
            </div>
            <div class="col-12 standard-msg">
              ※ {{ $language('부서 마스터 화면에서 부서정보 중 [무재해 대상부서]만 자동으로 생성됩니다.') }}
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'uninjury-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      editable: true,
      saveUrl: transactionConfig.sai.uninjury.insert.url,
      mappingType: 'POST',
      isSave: false,
      data: {
        plantCd: null,  // 사업장코드
        uninjuryYear: '',  // 무사고 년도
        standardDate: '',  // 인원기준일
        avgCnt: null,  // 기준일기준 부서평균인원(가중인원대상부서)
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch: {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: this.$language('해당년도의 무재해 부서를 추가하시겠습니까?')+'['+this.data.uninjuryYear +']',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      if (result.data == 'X') {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '생성된 대상년도가 있습니다. 생성되지 않은 년도로 생성할 수 있습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('closePopup', {
          plantCd: this.data.plantCd,
          uninjuryYear: this.data.uninjuryYear,
        })
      }
    },
  },
};
</script>
<style scoped>
.standard-msg {
  color: red;
  font-size: 1em;
  padding-bottom: 20px !important;
}
</style>